'use strict';

var moduleName = 'footerSticky';

module.exports = function () {
	Array.from(document.querySelectorAll('.js-footer-sticky')).forEach(function (wrapper) {
		if (wrapper.dataset[moduleName + 'Init'] === 'true') return;
		wrapper.dataset[moduleName + 'Init'] = true;



		document.body.classList.add('styles-for-footer-sticky');

		window.addEventListener('resize', changeDescriptionStyles);



		function changeDescriptionStyles() {
			var heightFooter      = wrapper.offsetHeight;
			var descriptionStyles = '<style id="styles-for-footer-sticky">.styles-for-footer-sticky {margin-bottom: ' + heightFooter +'px;}</style>';
			var createdStyles     = document.querySelector('#styles-for-footer-sticky');

			if (createdStyles) createdStyles.remove();

			document.body.insertAdjacentHTML('beforeend', descriptionStyles);

			wrapper.style.position = 'absolute';
		}

		changeDescriptionStyles();



	});
};