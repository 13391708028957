"use strict";
var $ = window.jQuery = window.$ = require("./..\\..\\bower_components\\jquery\\dist\\jquery.js");


var initCustomWidgets = function () {
	require('./modules/navInit')();
	require('./modules/introSliderInit')();
	require('./modules/introScrollDownInit')();
	require('./modules/popupInit')();
	require('./modules/cataloguePreviewGalleryInit')();
	require('./modules/priceSliderInit')();
	require('./modules/customSelectInit')();
	require('./modules/servicesInit')();
    require('./modules/magnificInit')();
    require('./modules/footerStickyInit')();
};

$(initCustomWidgets);
