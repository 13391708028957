"use strict";

module.exports = function scrollbarWidth() {
    var $inner = $('<div style="width:100%; height:200px;">test</div>'),
        $outer = $('<div style="width:200px;height:150px;position:absolute;top:0;left:0;visibility:hidden;overflow:hidden;"></div>').append($inner),
        inner = $inner[0],
        outer = $outer[0];
     
    $('body').append(outer);
    var width1 = inner.offsetWidth;
    $outer.css('overflow', 'scroll');
    var width2 = outer.clientWidth;
    $outer.remove();
 
    return (width1 - width2);
};