"use strict";

module.exports = function () {

	$('.nav__link').on('click', function(){
		var $holder = $(this).parent('.nav__item'),
			$submenu = $($(this).attr('href'));

		if ($submenu.length) {
			if ($holder.is('.nav__item_open')) {
				$holder.removeClass('nav__item_open');
				$submenu.removeClass('nav__sm_open');
			} else {
				$('.nav__item_open').removeClass('nav__item_open');
				$('.nav__sm_open').removeClass('nav__sm_open');
				$holder.addClass('nav__item_open');
				$submenu.addClass('nav__sm_open');
			}
			return false;
		}
	});

};