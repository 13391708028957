"use strict";
require("./..\\..\\..\\bower_components\\jquery-ui\\ui\\core");
require("./..\\..\\..\\bower_components\\jquery-ui\\ui\\widget");
require("./..\\..\\..\\bower_components\\jquery-ui\\ui\\mouse");
require("./..\\..\\..\\bower_components\\jquery-ui\\ui\\slider");

module.exports = function () {
	if ($('#priceslider').length) {
		var $slider = $('#priceslider'),
			$sliderMin = $('#priceslider-min'),
			$sliderMax = $('#priceslider-max'),
			initialValues = [$sliderMin.val(), $sliderMax.val()],
			minValue = $slider.data('min'),
			maxValue = $slider.data('max');

		$slider.slider({
			range: true,
			min: minValue,
			max: maxValue,
			step: $slider.data('step'),
			values: [initialValues[0], initialValues[1]]
		});

		$slider.on('slide', function(event, ui){
			$sliderMin.val(ui.values[0]);
			$sliderMax.val(ui.values[1]);
		});

		$slider.on('slidestop', function(event, ui){
			$sliderMin.trigger('change.$');
			$sliderMax.trigger('change.$');
		});

		$sliderMin.on('change', function(){
			var min = $sliderMin.val() * 1,
				max = $sliderMax.val() * 1;

			if (min > maxValue) {
				$slider.slider('values', [maxValue, maxValue]);
			} else if (min < minValue) {
				$slider.slider('values', 0, minValue);
			} else if (min > max) {
				$slider.slider('values', [min, min]);
				$sliderMax.val(min);
			} else {
				$slider.slider('values', 0, min);
			}
		});

		$sliderMax.on('change', function(){
			var min = $sliderMin.val() * 1,
				max = $sliderMax.val() * 1;

			if (max < minValue) {
				$slider.slider('values', [minValue, minValue]);
			} else if (max > maxValue) {
				$slider.slider('values', 1, maxValue);
			} else if (max < min) {
				$slider.slider('values', [max, max]);
				$sliderMin.val(max);
			} else {
				$slider.slider('values', 1, max);
			}
		});
	}
};