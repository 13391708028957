"use strict";

module.exports = function () {

	$('.intro__scrollDown').on('click', function(){
		var goal = $(this).parents('.intro').next().offset().top;

		$('html, body').animate({
			scrollTop: goal
		}, 300);
	});

};