"use strict";
require("./..\\..\\..\\bower_components\\swiper\\dist\\js\\swiper.js");

module.exports = function () {

	$('.cpreview__gallerySlider')
		.after('<div class="cpreview__galleryPrev" /><div class="cpreview__galleryNext" />')
		.swiper({
			slidesPerView: 'auto',
			speed: 500,
			simulateTouch: false,
			prevButton: '.cpreview__galleryPrev',
			nextButton: '.cpreview__galleryNext'
		});

};