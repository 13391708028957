"use strict";
require("./..\\..\\..\\bower_components\\swiper\\dist\\js\\swiper.js");

module.exports = function () {

	$('.intro')
		.append('<div class="intro__pagination" /><div class="arrow arrow-left intro__prev" /><div class="arrow arrow-right intro__next" />')
		.swiper({
			effect: 'fade',
			autoplay: 5000,
			speed: 500,
			loop: true,
			simulateTouch: false,
			pagination: '.intro__pagination',
			paginationClickable: true,
			prevButton: '.intro__prev',
			nextButton: '.intro__next',
			bulletClass: 'intro__bullet',
			bulletActiveClass: 'intro__bullet_active'
		});

};