"use strict";

module.exports = function () {

	// Show dropdown
	function showDD(holder) {
		var $dd = holder.find('.services__dd');

		$dd.show();

		var delta = $dd.outerWidth() + $dd.offset().left - $(window).width();

		if (delta > 0) {
			$dd.css('left', - delta);
		} else {
			$dd.css('left', 0);
		}
	}

	// Hide dropdown
	function hideDD() {
		var $ddHolder = $('.services__item_active'),
			$dd = $ddHolder.find('.services__dd');

		$ddHolder.removeClass('services__item_active');
		$dd.css('left', 0).hide();
	}

	$('.services__link').on('click', function(){
		var $holder = $(this).parents('.services__item');

		if ($holder.is('.services__item_active')) {
			hideDD();
		} else {
			hideDD();
			$holder.addClass('services__item_active');
			showDD($holder);
		}
		return false;
	});

	$('.services__ddClose').on('click', hideDD);

	$(document).on('click', function(e){
		if (!$(e.target).parents('.services').length) {
			hideDD();
		}
	});

};