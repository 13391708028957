"use strict";

var scrollbarWidth = require('./scrollbarWidth');

module.exports = function () {
    $('[data-popup]').on('click', function(){
        var $popup = $('#' + $(this).data('popup')),
            $overlay = $('.overlay');

        if ($('.popup').is('.popup_visible')) {
            $('.popup_visible').hide();
        } else {
            $('body').
                css('margin-right', scrollbarWidth()).
                addClass('overlayed');
            $overlay.show(0, function(){
                $overlay.addClass('overlay_visible');
            });
        }
        if ($popup.outerHeight() > $(window).height() - 60) {
            $popup.css({
                'top': 30,
                'margin-top': 0
            });
        } else {
            $popup.css({
                'top': '50%',
                'margin-top': -$popup.outerHeight()/2
            });
        }

        // Buy popup
        if ($(this).data('popup') === 'buy') {
            var $buyForm = $('#buy-form'),
                buyName = $(this).data('name'),
                buyPrice = $(this).data('price');

            $('#buy-product-name').text(buyName);
            $buyForm.find('input[name="info"]').remove();
            $buyForm.append('<input type="hidden" name="info" value="' +
                         buyName + '. ' +
                         'Цена: ' + buyPrice + '">');
        }

        // Price request popup
        if ($(this).data('popup') === 'price-request') {
            var $prForm = $('#price-request-form'),
                prName = $(this).data('name');

            $('#price-request-product-name').text(prName);
            $prForm.find('input[name="info"]').remove();
            $prForm.append('<input type="hidden" name="info" value="' + prName + '">');
        }

        $popup.show(0, function(){
            $popup.addClass('popup_visible');
        });
        return false;
    });

    // Popup closing
    function popupClose() {
        $('.popup').removeClass('popup_visible');
        $('.overlay').removeClass('overlay_visible');
        setTimeout(function(){
            $('.popup').hide();
            $('.overlay').hide();
            $('body').
                css('margin-right', 0).
                removeClass('overlayed');
        }, 300);
    }

    $('[data-popup-close]').on('click', function(){
        popupClose();
        return false;
    });

    $('.overlay').on('click', function(e){
        if(!$(e.target).parents('.overlay').length){
            popupClose();
        }
    });

    $(document).keyup(function(e) {
        if (e.keyCode == 27 && $('.popup').is('.popup_visible')) {
            popupClose();
        }
    });
};